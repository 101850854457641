import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fenToYuan'
})
export class FenToYuanPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == null) {
      return '';
    }
    let v: any = value;
    let result: any = (v * 1) / 100;
    if (args) {
      if (args[0] == 'false') {
        result = result;
        return result;
      }
    }
    result = `${result}元`;
    return result;
  }
}
