import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUtils } from '@core/utils/dateUtils';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RequestMethods } from '../../services/RequestMethods';
import { LogService } from '../../services/log.service';
import { StoreService } from '../../services/store.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private logger: LogService,
    private storeService: StoreService
  ) {}
  /**
   * 发起一个get请求
   * @param url
   * @param paramMap
   * @param header
   */
  get(url: string, paramMap: {} = {}, header: {} = {}): Observable<object> {
    if (this.storeService.getToken()) {
      header['token'] = this.storeService.getToken();
    }
    return this.sendRequest(url, paramMap, RequestMethods.GET, header);
  }

  /**
   * 发起一个post请求
   * @param url
   * @param paramMap
   * @param header
   */
  post(url: string, paramMap: {} = {}, header: {} = {}): Observable<object> {
    if (this.storeService.getToken()) {
      header['token'] = this.storeService.getToken();
    }
    return this.httpClient.post(url, paramMap, { headers: header });
  }

  /**
   * 发起一个delete请求
   * @param url
   * @param paramMap
   * @param header
   */
  delete(url: string, paramMap: {} = {}, header: {} = {}): Observable<object> {
    return this.sendRequest(url, paramMap, RequestMethods.DELETE, header);
  }

  /**
   * 发起一个options请求
   * @param url
   * @param paramMap
   * @param header
   */
  options(url: string, paramMap: {} = {}, header: {} = {}): Observable<object> {
    return this.sendRequest(url, paramMap, RequestMethods.OPTIONS, header);
  }
  /**
   * 通用的请求方法，通过传入RequestMethods不同以发起不用类型的请求。
   * 默认发起一个Get请求
   * @param url 必传参数
   * @param paramMap 默认为空
   * @param method 默认GET
   */
  request(url: string, paramMap: {} = {}, method: RequestMethods = RequestMethods.GET, header: {} = {}): Observable<object> {
    return this.sendRequest(url, paramMap, method, header);
  }
  protected sendRequest(url: string, paramMap: any = {}, method: RequestMethods = RequestMethods.GET, header: {} = {}): Observable<object> {
    if (paramMap) {
    }
    if (header) {
    }
    return new Observable(observer => {
      this.httpClient.request(method, url, { params: paramMap, headers: header }).subscribe(res => {
        observer.next(res);
      });
    });
  }
  async readFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = e => {
        resolve(e.target.result);
      };
      reader.onerror = reject;
    });
  }
  public showSuccess(msg) {}

  public showError(msg) {}
  /**
   * 发起一个post请求
   * @param url
   * @param paramMap
   * @param header
   */
  downloadExcel(url: string, paramMap: {} = {}, header: {} = {}) {
    if (this.storeService.getToken()) {
      header['token'] = this.storeService.getToken();
    }
    if (this.storeService.getSys_user_id()) {
      header['userid'] = this.storeService.getSys_user_id();
    }
    return this.httpClient
      .post(url, paramMap, {
        headers: header,
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        mergeMap(async ev => {
          let body: any = ev.body;

          let bodyType = body.type; //type: "text/xml"
          if (bodyType == 'text/xml') {
            this.downloadExcelFile(ev.body);
          } else {
            let result = null;
            await this.readFile(ev.body).then(rre => {
              result = JSON.parse(rre);
              if (result.code) {
                this.showError(result.msg);
              } else {
                this.showSuccess(result.msg);
              }
            });
            return result;
          }
          let kk = { code: 0, msg: '成功' };
          return kk;
        })
      );
  }
  private downloadExcelFile(data: any) {
    // 下载类型 xls
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    // 下载类型：csv
    const contentType2 = 'text/csv';
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    // 打开新窗口方式进行下载
    // window.open(url);

    // 以动态创建a标签进行下载
    const a = document.createElement('a');
    const fileName = DateUtils.getNowYYMMDDHHmmSS();
    a.href = url;
    // a.download = fileName;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
