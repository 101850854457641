export const environment = {
  production: true,
  isShowConsole: true,
  openid: '',
  isTest: false,
  isRedirect: true,
  isInPC: false,
  sys_user_id: '',
  domain: 'https://app.jls2sc.com',
  testOpenid: 'okAfLjv571-9hC9HraKFbaKExF88',
  testUserid: '2044604440935014553'
  /*testOpenid:'okAfLjp4wkSQSEFqvkuUSTHYF6II',
 testUserid:'6594292965475'*/
};
