export class UrlUtil {
  url: string = '';
  params = {};
  constructor(url) {
    if (!url) {
      return;
    }
    let splitArray = url.split('?');
    this.url = splitArray[0];
    if (splitArray && splitArray.length > 1) {
      let params = splitArray[1];
      let paramsArray = params.split('&');
      for (let i = 0; i < paramsArray.length; i++) {
        let item = paramsArray[i];
        var keyAndValueArray = item.split('=');
        let key = keyAndValueArray[0];
        let value;
        if (keyAndValueArray.length > 1) {
          value = keyAndValueArray[1];
        }
        if (value != 0 && value != null && value != '') {
          if (!this.params) {
            this.params = {};
          }
          this.params[key] = value;
        }
      }
    }
  }
  getFullUrl(ignorParams: String[] = null) {
    let result = this.url;
    let paramsString = '';
    if (this.params) {
      for (let p in this.params) {
        if (ignorParams) {
          if (ignorParams.includes(p)) {
            continue;
          }
        }
        if (paramsString) {
          paramsString += `&${p}=${this.params[p]}`;
        } else {
          paramsString += `${p}=${this.params[p]}`;
        }
      }
    }
    if (paramsString) {
      result = `${result}?${paramsString}`;
    }
    return result;
  }
}
