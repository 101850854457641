import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { moveUpMotion } from '@shared/component/core/animation/move';
import { MessageConfig } from '@shared/component/core/config/config';
import { NzConfigService } from '@shared/component/core/config/config.service';
import { toCssPixel } from '@shared/component/core/util';
import { WMessageDataFilled, WMessageDataOptions } from '@shared/component/w-msg/typings';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const NZ_CONFIG_COMPONENT_NAME = 'message';
const NZ_MESSAGE_DEFAULT_CONFIG: Required<MessageConfig> = {
  nzAnimate: true,
  nzDuration: 3000,
  nzMaxStack: 7,
  nzPauseOnHover: true,
  nzTop: 80
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  selector: 'app-w-msg-container',
  templateUrl: './w-msg-container.component.html',
  styleUrls: ['./w-msg-container.component.scss'],
  animations: [moveUpMotion]
})
export class WMsgContainerComponent implements OnInit {
  destroy$ = new Subject<void>();
  messages: WMessageDataFilled[] = [];
  config: Required<MessageConfig>;
  top: string | null;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected nzConfigService: NzConfigService
  ) {
    this.updateConfig();
  }

  ngOnInit(): void {
    this.subscribeConfigChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Create a new message.
   * @param message Parsed message configuration.
   */
  createMessage(message: WMessageDataFilled): void {
    if (this.messages.length >= this.config.nzMaxStack) {
      this.messages = this.messages.slice(1);
    }
    message.options = this.mergeMessageOptions(message.options);
    message.onClose = new Subject<boolean>();
    this.messages = [...this.messages, message];
    this.cdr.detectChanges();
  }

  /**
   * Remove a message by `messageId`.
   * @param messageId Id of the message to be removed.
   * @param userAction Whether this is closed by user interaction.
   */
  removeMessage(messageId: string, userAction: boolean = false): void {
    this.messages.some((message, index) => {
      if (message.messageId === messageId) {
        this.messages.splice(index, 1);
        this.messages = [...this.messages];
        this.cdr.detectChanges();
        message.onClose!.next(userAction);
        message.onClose!.complete();
        return true;
      }
      return false;
    });
  }

  /**
   * Remove all messages.
   */
  removeMessageAll(): void {
    this.messages = [];
    this.cdr.detectChanges();
  }

  protected updateConfig(): void {
    this.config = this.updateConfigFromConfigService();
    this.top = toCssPixel(this.config.nzTop);
    this.cdr.markForCheck();
  }

  protected subscribeConfigChange(): void {
    this.nzConfigService
      .getConfigChangeEventForComponent(NZ_CONFIG_COMPONENT_NAME)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateConfig());
  }

  protected updateConfigFromConfigService(): Required<MessageConfig> {
    return {
      ...NZ_MESSAGE_DEFAULT_CONFIG,
      ...this.config,
      ...this.nzConfigService.getConfigForComponent(NZ_CONFIG_COMPONENT_NAME)
    };
  }

  /**
   * Merge default options and custom message options
   * @param options
   */
  protected mergeMessageOptions(options?: WMessageDataOptions): WMessageDataOptions {
    const defaultOptions: WMessageDataOptions = {
      nzDuration: this.config.nzDuration,
      nzAnimate: this.config.nzAnimate,
      nzPauseOnHover: this.config.nzPauseOnHover
    };
    return { ...defaultOptions, ...options };
  }
}
