import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { HttpService } from '@shared/service/http.service';
import { StoreService } from './services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  m_buttonList: String[] = [];
  timmer;
  constructor(
    public platform: Platform,
    public httpClient: HttpService,
    public storeService: StoreService,
    public menuController: MenuController,
    public router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.storeService.initClientid();
    if (this.storeService.getStoreDate() != this.storeService.getDayString()) {
      this.storeService.clearAll();
      this.storeService.setStoreDate();
    }
    this.storeService.setFirstHref(location.href);
    this.platform.ready().then(() => {
      this.storeService.setPlateForm(this.platform.platforms());
      document.addEventListener('backbutton', () => {
      });
      this.platform.backButton.subscribe(r => {});
    });
    this.m_buttonList = this.storeService.getPermissionKeys();
    this.timmer = setInterval(() => {
      let currentDate = new Date();
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();
      //一点的时候刷新页面
      if (hours == 1 && minutes == 2) {
        location.reload();
      }
    }, 10000);
  }
  relogin() {
    this.storeService.clearAll();
    location.reload();
  }
  closeMenu() {
    this.menuController.close('first');
    this.menuController.close('end');
    this.menuController.close('custom');
  }
  gotoHome() {
    this.goto('/member');
    this.closeMenu();
  }
  gotobaseinfo() {
    this.goto('/member/baseinfo');
    this.closeMenu();
  }
  gotopayBill() {
    this.goto('/pay/payBill');

    this.closeMenu();
  }
  gotopayBillRefund() {
    this.goto('/pay/payBillRefund');
    this.closeMenu();
  }
  gotogateChargeLog() {
    this.goto('/gate/gateChargeLog');

    this.closeMenu();
  }

  gotogateScanOut() {
    this.goto('/gate/gateScanOut');
    this.closeMenu();
  }

  goto(url) {
    let marketId = this.storeService.getmarketId();
    this.router.navigate([url], {
      queryParams: {
        marketId: marketId,
        mid: marketId
      }
    });
  }
  gotocartrade() {
    this.goto('/cartrade');
    this.closeMenu();
  }

  gotosellcar() {
    this.goto('/carstock/sellcar');

    this.closeMenu();
  }
  gotocarstock() {
    this.goto('/carstock');
    this.closeMenu();
  }
}
