import { Injectable } from '@angular/core';
import { AuthAccountService } from '@api/sys/service/auth-account.service';
import { R } from '@shared/base/r';
import { HttpService } from '@shared/service/http.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  openidkey = 'op3';
  sysusekey = 'syuc';
  tokenKey = 'toks';
  permissionKeys = 'permissionKeys';
  firstHref = 'firstHref';
  version = '20210120.01';
  storeDateKey = 'storeDate';
  private plateFormKey = 'plateForm';
  private marketIdKey = 'marketId';
  clientidKey = 'clientid';
  constructor(
    public cookie: CookieService,
    private httpClient: HttpClient
  ) {}
  clearAll() {
    let appTitle = localStorage.getItem('appTitle');
    let clientId = localStorage.getItem(this.clientidKey);
    this.cookie.deleteAll();
    localStorage.clear();
    localStorage.setItem('appTitle', appTitle);
    localStorage.setItem(this.clientidKey, clientId);
  }
  public setmarketId(value) {
    this.setValue(this.marketIdKey, value);
  }
  public getmarketId() {
    return this.getValue(this.marketIdKey, false);
  }
  public setPlateForm(value) {
    this.setValue(this.plateFormKey, value);
  }

  public getPlateForm() {
    let value = this.getValue(this.plateFormKey);
    return value;
  }
  public getStoreDate() {
    return this.getValue(this.storeDateKey);
  }

  public setStoreDate() {
    this.setValue(this.storeDateKey, this.getDayString());
  }

  public getDayString() {
    let kk = new Date();
    let result = `${kk.getFullYear()}-${kk.getMonth()}-${kk.getDate()}`;
    return result;
  }



  public getOpenid() {
    let value = this.getValue(this.openidkey);
    if (!value) {
      if (environment.isInPC) {
        this.setOpenid(environment.testOpenid);
        value = environment.testOpenid;
      }
    }
    return value;
  }

  public setFirstHref(value) {
    this.setValue(this.firstHref, value);
  }

  public getFirstHref() {
    let value = this.getValue(this.firstHref);
    return value;
  }
  public setClientId(value) {
    this.setValue(this.clientidKey, value);
  }

  public getClientId() {
    let value = this.getValue(this.clientidKey);
    return value;
  }
  public setOpenid(value) {
    this.setValue(this.openidkey, value);
  }

  public setSys_user_id(value) {
    this.setValue(this.sysusekey, value);
  }

  public setToken(value) {
    this.setValue(this.tokenKey, value);
  }

  public setPermissionKeys(value) {
    this.setValue(this.permissionKeys, value, true);
  }

  public getPermissionKeys(): String[] {
    let value = this.getValue(this.permissionKeys, true);
    if (!value) {
      return [];
    }
    return value;
  }

  public getToken() {
    let value = this.getValue(this.tokenKey);

    return value;
  }

  public getSys_user_id() {
    let value = this.getValue(this.sysusekey);
    if (!value) {
      if (environment.isInPC) {
        this.setSys_user_id(environment.testUserid);
        value = environment.testUserid;
      }
    }
    return value;
  }

  clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    if (keys) {
      for (var i = keys.length; i--; ) {
        document.cookie = `${keys[i]}=0;expires=${exp.toUTCString()}; path=/`;
      }
    }
  }
  initClientid() {
    let idcode = this.getClientId();
    if (!idcode) {
      this.httpClient.post('/api/system/auth/authAccount/getNewClientid', {}, {}).subscribe((r: any) => {
        if (r.code) {
          this.setClientId(r.data);
        }
      });
    }
  }
  public getValue(key, isObject = false) {
    let value = '';
    if (!value) {
      try {
        value = localStorage.getItem(key);
      } catch (e) {
        console.log(`获取key${key}失败`);
      }
    }
    if (isObject && value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.log(`getValue出错${key}`);
        return null;
      }
    }
    return value;
  }

  public setValue(key, value, isObject = false) {
    if (isObject) {
      if (value != null && value != undefined) {
        try {
          value = JSON.stringify(value);
        } catch (e) {
          console.log(`setValue${key}出错`);
        }
      }
    }
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  }
}
