import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { NzOutletModule } from '@shared/component/core/outlet';
import { WMsgContainerComponent } from '@shared/component/w-msg/w-msg-container/w-msg-container.component';
import { WMsgServiceModule } from '@shared/component/w-msg/w-msg-service.module';
import { WMsgComponent } from '@shared/component/w-msg/w-msg.component';

@NgModule({
  declarations: [WMsgComponent, WMsgContainerComponent],
  imports: [CommonModule, WMsgServiceModule, OverlayModule, BrowserAnimationsModule, NzOutletModule, IonicModule]
})
export class WMsgModule {}
