import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FenPipe } from '@core/pipe/fen.pipe';
import { SafeInnerHtmlPipe } from '@core/pipe/safeInnerHtmlPipe';
import { DatimeStrPipe } from './datime-str.pipe';
import { FenToYuanPipe } from './fen-to-yuan.pipe';

@NgModule({
  declarations: [FenPipe, DatimeStrPipe, FenToYuanPipe, SafeInnerHtmlPipe],
  imports: [CommonModule],
  exports: [FenPipe, DatimeStrPipe, FenToYuanPipe, SafeInnerHtmlPipe]
})
export class PipsModule {}
