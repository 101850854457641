import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor() {}
  log(message?: any, ...optionalParams: any[]) {
    console.log(message, optionalParams);
  }
}
