import * as moment from 'moment';

export class DateUtils {
  static toStrYYYYMMDD(dateValue): string {
    if (!dateValue) {
      return '';
    }
    let result = moment(dateValue).format('YYYY-MM-DD');
    return result;
  }
  static toStrYYYY(dateValue): string {
    if (!dateValue) {
      return '';
    }
    return moment(dateValue).format('YYYY');
  }
  static toStrMM(dateValue): string {
    if (!dateValue) {
      return '';
    }
    return moment(dateValue).format('MM');
  }
  static toStrDD(dateValue): string {
    if (!dateValue) {
      return '';
    }
    return moment(dateValue).format('DD');
  }
  static toDateYYYY_MM_DD(dateValue): Date {
    if (!dateValue) {
      return null;
    }
    return moment(moment(dateValue).format('YYYY-MM-DD')).toDate();
  }
  static toDateYYYY_MM_DD_HH_mm_ss(dateValue): Date {
    if (!dateValue) {
      return null;
    }
    return moment(moment(dateValue).format('YYYY-MM-DD HH:mm:ss')).toDate();
  }
  static toDateTimeStr(dateValue: Date): string {
    if (!dateValue) {
      return null;
    }
    return moment(dateValue).format('YYYY-MM-DD HH:mm:ss');
  }

  static strToDate(str) {
    if (!str) {
      return null;
    }
    let typeName = typeof str;
    if (typeName == 'string') {
      let length = str.length;
      if (length == 'YYYYMMDD'.length) {
        return moment(moment(str).format('YYYYMMDD')).toDate();
      } else if (length == 'YYYY-MM-DD'.length) {
        return moment(moment(str).format('YYYY-MM-DD')).toDate();
      } else if (length == 'YYYY-MM-DD HH:mm:ss'.length) {
        return moment(moment(str).format('YYYY-MM-DD HH:mm:ss')).toDate();
      }
    }

    return null;
  }
  static toDate(str, format) {
    return moment(moment(str).format(format)).toDate();
  }
  static toDateYYYYMMDD(dateValue): Date {
    if (!dateValue) {
      return null;
    }
    return moment(moment(dateValue).format('YYYYMMDD')).toDate();
  }
  static getNowDayTimeStr(): string {
    return moment().format('YYYY-MM-DD HH:mm:ss');
  }

  static toDateFromYYYYMMDD_noSplit(str) {
    if (!str) {
      return '';
    }
    return moment(str, 'YYYYMMDD');
  }

  static noSplitToYYYYMMDD(str) {
    if (!str) {
      return '';
    }
    return moment(str, 'YYYYMMDD').format('YYYY-MM-DD');
  }
  static getNowYYMMDDHHmmSS() {
    return moment().format('YYYYMMDDHHmmss');
  }

  /**
   * 获得当前年份的第一天
   */
  static getCurrentYearFirstDate() {
    return moment().format('YYYY-01-01');
  }

  static getCurrentDateStr() {
    return moment().format('YYYY-MM-DD');
  }
  static getCurrentDate() {
    return moment(moment().format('YYYY-MM-DD')).toDate();
  }
  static plusDays(dateValue, addNumber) {
    return moment(moment(dateValue)).add(addNumber, 'days').toDate();
  }
  static getTomorrowDate() {
    return moment(moment().format('YYYY-MM-DD')).add(1, 'days').toDate();
  }
  static formateToStr(dateValue, formateStr: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm:ss') {
    return moment(dateValue).format(formateStr);
  }
}
