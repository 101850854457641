import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { moveUpMotion } from '@shared/component/core/animation/move';
import { WMessageDataFilled, WMessageDataOptions } from '@shared/component/w-msg/typings';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'w-msg',
  templateUrl: './w-msg.component.html',
  styleUrls: ['./w-msg.component.scss'],
  preserveWhitespaces: false,
  animations: [moveUpMotion]
})
export class WMsgComponent implements OnInit {
  @Input() nzMessage: WMessageDataFilled;
  @Input() nzIndex: number;
  @Output() readonly messageDestroy = new EventEmitter<{ id: string; userAction: boolean }>();
  protected options: Required<WMessageDataOptions>;

  // Whether to set a timeout to destroy itself.
  private autoClose: boolean;
  private secondTimer;
  public second: number = null;
  private eraseTimer;
  private eraseTimingStart: number;
  private eraseTTL: number; // Time to live.
  constructor(protected cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // `NzMessageContainer` does its job so all properties cannot be undefined.
    this.options = this.nzMessage.options as Required<WMessageDataOptions>;
    if (this.options.nzAnimate) {
      this.nzMessage.state = 'enter';
    }
    this.autoClose = this.options.nzDuration > 0;
    if (this.autoClose) {
      this.initErase();
      this.startEraseTimeout();
      this.startSecond();
    }
  }

  ngOnDestroy(): void {
    if (this.autoClose) {
      this.clearEraseTimeout();
    }
  }

  onEnter(): void {
    if (this.autoClose && this.options.nzPauseOnHover) {
      this.clearEraseTimeout();
      this.updateTTL();
    }
  }

  onLeave(): void {
    if (this.autoClose && this.options.nzPauseOnHover) {
      this.startEraseTimeout();
    }
  }

  // Remove self
  public destroy(userAction: boolean = false): void {
    if (this.options.nzAnimate) {
      this.nzMessage.state = 'leave';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.messageDestroy.next({ id: this.nzMessage.messageId, userAction: userAction });
      }, 200);
    } else {
      this.messageDestroy.next({ id: this.nzMessage.messageId, userAction: userAction });
    }
    this.clearSecond();
  }

  private initErase(): void {
    this.eraseTTL = this.options.nzDuration;
    this.eraseTimingStart = Date.now();
  }

  private updateTTL(): void {
    if (this.autoClose) {
      this.eraseTTL -= Date.now() - this.eraseTimingStart;
    }
  }

  private startEraseTimeout(): void {
    if (this.eraseTTL > 0) {
      this.clearEraseTimeout();
      this.eraseTimer = setTimeout(() => this.destroy(), this.eraseTTL);
      this.eraseTimingStart = Date.now();
    } else {
      this.destroy();
    }
  }

  private startSecond(): void {
    if (this.eraseTTL > 0) {
      this.second = this.eraseTTL / 1000;
      this.secondTimer = setInterval(() => {
        this.second--;
        if (this.second <= 0) {
          clearInterval(this.secondTimer);
        }
        this.cdr.detectChanges();
      }, 1000);
    }
  }
  private clearSecond() {
    if (this.secondTimer) {
      clearInterval(this.secondTimer);
    }
  }
  private clearEraseTimeout(): void {
    if (this.eraseTimer !== null) {
      clearTimeout(this.eraseTimer);
      this.eraseTimer = null;
    }
  }
}
