import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { UrlUtil } from '@core/utils/url-util';
import { LoadingController } from '@ionic/angular';
import { HttpService } from '@shared/service/http.service';
import { Observable } from 'rxjs';
import { StoreService } from './store.service';

//若依赖中用到了storage则需要 import {IonicStorageModule,Storage} from '@ionic/storage';否则会失败
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private storeService: StoreService,
    private httpClient: HttpService,
    public loadingController: LoadingController
  ) {}
  async presentLoadingV2(msg) {
    const loading = await this.loadingController.create({
      message: msg,
      backdropDismiss: false,
      showBackdrop: false,
      keyboardClose: false
    });
    loading.present();
    return loading;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let openid = this.storeService.getOpenid();
    if (openid) {
      if (state.url.indexOf('/member/register') >= 0) {
        return true;
      }
      let sourceUrl = state.url;
      let urlUtil = new UrlUtil(sourceUrl);
      let token = this.storeService.getToken();
      let marketId = urlUtil.params['marketId'];
      let marketIdSource = this.storeService.getmarketId();
      if (marketId && marketIdSource && marketIdSource != marketId) {
        let dddd = this.presentLoadingV2(`marketId${marketId}marketIdSource:${marketIdSource}`);
        this.storeService.clearAll();
        setTimeout(() => {
          dddd.then(ddddd => ddddd.dismiss());
          this.router.navigate(['/sys/login'], {
            queryParams: {
              sourceUrl: sourceUrl,
              marketId: marketId
            }
          });
        }, 1);

        return false;
      }
      if (!token) {
        this.router.navigate(['/sys/login'], {
          queryParams: {
            sourceUrl: sourceUrl,
            marketId: marketId
          }
        });
        return false;
      }
      return true;
    } else {
      let sourceUrl = state.url;
      let urlUtil = new UrlUtil(sourceUrl);
      let weixin_openid = urlUtil.params['weixin_openid'];
      let sign = urlUtil.params['sign'];
      let timestamp = urlUtil.params['timestamp'];
      let marketId = urlUtil.params['marketId'];
      let marketIdSource = this.storeService.getmarketId();
      if (marketId && marketIdSource && marketIdSource != marketId) {
        this.storeService.clearAll();
      }
      this.router.navigate(['/sys/login'], {
        queryParams: {
          sourceUrl: sourceUrl,
          weixin_openid: weixin_openid,
          sign: sign,
          timestamp: timestamp,
          marketId: marketId
        }
      });
      return false;
    }
    return true;
  }
}
