import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { PipsModule } from '@core/pipe/pips.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { WMsgModule } from '@shared/component/w-msg/w-msg.module';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuard } from './services/login.guard';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: true
    }),
    AppRoutingModule,
    PipsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    WMsgModule
  ],
  providers: [CookieService, LoginGuard, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: NZ_I18N, useValue: zh_CN }],
  exports: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
