import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fen'
})
export class FenPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == null) {
      return 0;
    }
    let i: any = value;
    let num = i * 1;
    num = num / 100;
    return `${num}元`;
  }
}
